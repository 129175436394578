@import 'src/common/CommonVariables';

.switch-preview-first {
  border: 1px solid $base-40;
  border-right: 0;
  border-radius: 18px 0px 0px 18px;
  height: 35px;
  background: white;
}

.switch-preview-second {
  border: 1px solid $base-40;
  border-radius: 0px 18px 18px 0px;
  height: 35px;
  background: white;
}

.background-grey {
  background: $base-10 !important;
}
