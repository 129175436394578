@import 'src/common/CommonVariables';

.numerical-columns {
  width: 100%;
  display: flex;
  gap: 4px;
}
.numerical-columns-first-child,
.numerical-columns-second-child {
  width: 100px;
}
.numerical-columns-third-child {
  width: 250px;
}

.remove-numerical-columns,
#add-numerical-columns {
  min-width: unset !important;
  min-height: unset !important;
  padding: unset !important;
  border-radius: 13px !important;
  width: 32px !important;
  height: 30px !important;
  font-size: 35px !important;
  background: black;
  color: white;
}

.remove-numerical-columns {
  width: 100px !important;
  background: white !important;
  color: gray !important;
  align-self: center !important;
}

.grouping-body {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-top: solid 1px #80808026;
  padding-bottom: 10px;
  button {
    max-width: 30px;
  }
}

.grouping-radios {
  min-width: 370px !important;
  color: $base-80 !important;
  .Mui-checked {
    color: $base-80 !important;
  }
  div {
    display: flex;
    flex-direction: row;
  }
}

.grouping-textual-input {
  display: flex;
  border: 1px solid #00000038;
  border-radius: 12px;
  padding: 9px 15px;
  .MuiFormControl-root {
    display: contents;
  }
}
.grouping-textual-input:hover {
  border-color: #000000 !important;
}
.grouping-textual-input:focus-within {
  outline: none !important;
  border-color: $accent !important;
  box-shadow: $focusBox-shadow-color !important;
}

.grouping-example {
  div {
    margin: 10px 0px;
  }
  p {
    margin: 5px 0px;
  }
}
