@import 'src/themes/foundations/Typography.scss';

.feature-item {
  width: 150px;
  display: flex;
  flex-direction: column;

  label {
    color: black !important;
  }

  div {
    width: inherit;
  }
}

.date-picker {
  width: 150px !important;

  div {
    width: auto !important;
    height: 56px !important;
  }

  input {
    font-size: $ft-body__small;
  }

  input::placeholder {
    font-size: $ft-body__small;
  }
}
