@import 'src/common/CommonVariables';

.context {
  &__footer {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
  &__legend {
    display: flex;
    align-items: baseline;
    gap: 15px;
    color: $base-80;
    font-size: $ft-body__small;
    div {
      display: flex;
      gap: 5px;
      align-items: center;
    }
    span {
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }
    #succeed-span {
      background-color: $success;
    }
    #warning-span {
      background-color: $warning;
    }
    #error-span {
      background-color: $alert;
    }
  }
  &__info {
    width: 30%;
    background-color: $info-95;
    .alertBox {
      padding: 12px 20px 12px 20px !important;
    }
  }
  .preview-table-row {
    th:nth-child(3),
    th:nth-child(3) {
      max-width: 330px !important;
    }
  }
  .wrapp-preview-table {
    max-height: calc(70vh - 150px) !important;
  }
}

@media (max-height: 850px) {
  .context {
    .wrapp-preview-table {
      max-height: calc(70vh - 200px) !important;
    }
  }
}
