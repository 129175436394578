.upload-alert {
  &__modal-header {
    margin-bottom: 10px;
  }
  .individual-body {
    width: 100% !important;
  }
  .dialog-footer {
    width: 100%;
  }
  .multiple-paragraphs {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
