@import 'src/common/CommonVariables';

@mixin background-opacity($color, $opacity: 0.3) {
  background: $color; /* The Fallback */
  background: rgba($color, $opacity);
}

.table-label {
  font-size: $ft-body__medium;
}

.table-header {
  position: sticky;
  top: 0;
}

.preview-rows-table {
  overflow-x: auto;

  table tbody {
    display: block;
    max-height: 330px;
    overflow-y: scroll;
  }

  table thead,
  table tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
    background-color: transparent !important;
  }

  table {
    font-size: $ft-body__small;
    width: 100%;
    border-collapse: collapse;
  }
}

.preview-rows-table tr {
  border-bottom: 1px solid #ddd;
}

.preview-rows-table-row {
  td {
    border-bottom: 1px solid $base-30;
  }

  th {
    background-color: $base-10;
    border-bottom: 1px solid $base-60;
  }

  th,
  td {
    height: 24px;
    padding: 13px;
    text-align: left !important;
  }

  .preview-flex {
    display: flex;
    gap: 5px;
    justify-content: flex-start;
  }
}

.rows-first-column {
  padding-left: 35px !important;
}

.transform-selected {
  color: white !important;
  background-color: $base-80 !important;
}

.transform-selected p {
  color: white !important;
}

.preview-rows-tablew-body {
  td {
    background-color: white;
  }
}

.preview-rows-table-row:last-child {
  td {
    height: 24px;
    padding: 13px;
    border-bottom: none;
  }
}

.preview-context {
  font-size: $ft-body__medium !important;
  color: $base-90;
  text-align: left;
}

.rowsCategoricalOpen {
  max-width: 100%;
  flex-wrap: wrap;

  .chip {
    margin: 0.2em;
  }
}

.normal-header {
  width: 110px !important;
}

.normal-row {
  width: 121px !important;
}

.model-variable-header {
  background-color: $base-30 !important;
  width: 110px !important;
}

.model-variable-row {
  background-color: $base-10;
  width: 120px !important;
}

.predict-variable-header {
  background-color: $accent !important;
  color: white !important;
  width: 110px !important;
}

.predict-variable-row {
  background-color: $accent-92 !important;
  width: 120px !important;
}

.probability-header {
  background-color: $base-80 !important;
  color: white !important;
  width: 110px !important;
}

.probability-row {
  background-color: $base-10 !important;
  width: 117px !important;
}

.preview-rows-table-pagination {
  margin-top: 1em;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.preview-rows-table tbody::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
}

.preview-rows-table tbody::-webkit-scrollbar-track {
  background-color: transparent;
}

.preview-rows-table tbody::-webkit-scrollbar-thumb {
  border-radius: 10px;
}

.preview-rows-table tbody::-webkit-scrollbar-track-piece:start {
  background: transparent;
}

.disregard-row {
  background-color: $base-20;
  opacity: 0.5;
}

.merge-disregard-row {
  @include background-opacity($alert, 0.2);
}

.row-edited {
  border-left: 2px solid $accent !important;
}

.row-duplicated {
  @include background-opacity($color-9, 0.2);
}

.row-added {
  border-left: 2px solid $color-5 !important;
}

thead {
  .disregard-row {
    border-bottom: 2px solid purple !important;
    background-color: $base-20;
    opacity: 0.5;
  }
  .merge-disregard-row {
    background-color: $alert !important;
    color: white !important;
  }
  .row-duplicated {
    background-color: $color-9 !important;
  }
}

.merged-columns-center {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  .chip {
    border-radius: 15px !important;
  }
}

.preview-rows-table::-webkit-scrollbar {
  width: 15px;
  background-color: transparent;
}

.preview-rows-table::-webkit-scrollbar-track {
  border-radius: 10px;
}

.preview-rows-table::-webkit-scrollbar-thumb {
  border-radius: 10px;
}

.allowed-bulk-row {
  display: flex !important;
  align-items: center;
  width: 100%;
  height: 98.5%;
  border-bottom: 1px solid #e0e0e0;
  justify-content: space-between;
  cursor: pointer;
  .bulk-allowed-icon {
    display: flex;
    width: 32px;
    height: 100%;
    min-height: max-content;
    padding: 0 5px 0 5px;
    & svg * {
      stroke: $base-90 !important;
    }
  }
  .bulk-allowed-icon:hover {
    & svg * {
      stroke: $accent !important;
    }
  }
  div > * {
    width: 100%;
  }
  td {
    width: 100% !important;
    display: contents;
  }

  .rowsCategoricalOpen {
    display: flex !important;
    width: 100%;
    height: 100%;
    align-items: center;
  }
}

.summary-selected {
  background-color: $base-80 !important;
  * {
    color: white !important;
  }
  .bulk-allowed-icon {
    & svg * {
      stroke: white !important;
    }
  }
}
