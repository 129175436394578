.imgSize {
  width: auto;
  height: auto;
  max-width: 96%;
  max-height: 80vh;
  border-radius: 16px;
}

.customImageBox {
  padding: 5px 2px;
  width: 50%;
  height: fit-content;
}

#image-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  user-select: none;
  margin: auto;
  border-radius: 10px;
}

@media (max-width: 700px) {
  .imgSize {
    width: 100%;
  }
}
