@import 'src/common/CommonVariables';

.smallCheckboxSelect {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border: solid 1px $base-40;
  border-radius: 8px;
}
