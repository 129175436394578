#scenario-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &__selectors {
    display: flex;
    flex-direction: row;
    gap: 24px;
  }
}
