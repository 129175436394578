@import 'src/common/CommonVariables';

#insights {
  &__body {
    grid-row: body;
    display: grid;
    grid-template-rows: [header] auto [body] auto;
    overflow: hidden;

    &__header {
      grid-row: header;
      width: auto;
      border-radius: 8px 8px 0 0;

      &--statement {
        display: flex;
        flex-direction: row;
        padding: 16px 24px;
        gap: 24px;
        font-size: $ft-body__medium;
        background-color: $base-10;

        .insights-statement {
          display: flex;
          align-items: center;
          gap: 8px;
          text-wrap: nowrap;
          font-size: inherit;

          &__text {
            max-width: 55vw;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .show-more-text {
            text-wrap: wrap;
          }

          &__show-more-btn {
            color: $base-70;
            text-decoration: underline;
            cursor: pointer;
            font-size: $ft-body__small;
          }
        }

        #disregard-button {
          background-color: $base-10;
          color: $base-90;
          font-weight: bold;
          padding: 0 !important;
          font-size: inherit;
        }
      }
    }

    &__content {
      grid-row: body;
      display: grid;
      grid-template-columns: [descriptions]3.33fr [images] 5fr;
      overflow: hidden;

      &__body-details {
        grid-column: descriptions;
        display: flex;
        padding-right: 16px;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        align-self: stretch;
        overflow: hidden;

        .insights-title-text {
          font-size: 12px;
          color: $base-60;
        }

        #insights-description-container {
          overflow-y: scroll;
          display: flex;
          flex-direction: column;
          gap: 10px;
          padding-right: 5px;
          width: 100%;
          .insights-title-number {
            font-size: $ft-body__large;
            color: $base-90;
            opacity: 0.5;
          }
          .insights-title-descriptions {
            display: flex;
            padding: 24px 24px 24px 24px;
            flex-direction: column;
            gap: 5px;
            align-self: stretch;
            border-radius: 8px;
            border: 1px solid $accent;
            color: $base-90;
            background: $base-10;
            cursor: pointer;

            &:hover {
              color: $base-90;
              background: #a82aab1a;
            }

            .insight-text {
              max-height: 64px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              white-space: initial;
              -webkit-line-clamp: 3;
              line-clamp: 3;
              -webkit-box-orient: vertical;
            }
          }

          .active-insight,
          .active-insight:hover {
            .insights-title-number {
              color: white;
            }
            max-height: fit-content;
            color: white !important;
            background: $accent !important;

            .insight-text {
              display: block;
              max-height: fit-content;
            }
          }
        }

        .insights-alert {
          width: calc(100% - 32px);
        }
      }
      &__body-plots {
        display: grid;
        grid-template-rows: [header] 48px [body] 1fr;
        background-color: $base-10;
        border-radius: 8px;
        overflow: hidden;
        .Mui-selected {
          color: $base-90;
          font-weight: bold;
        }
        .MuiTabs-indicator {
          background-color: $accent;
        }
        .insights-tab-images {
          padding: 12px 12px;
          position: relative;

          .insights-tab-images-with-button {
            display: flex;
            gap: 0px;
            margin-bottom: 16px;
            max-width: 100%;
            padding-top: 10px;

            .insights-tab-images-expand-button {
              position: absolute;
              top: 8px;
              left: 8px;
              background: transparent !important;
              padding: 0px !important;
              margin: 0px !important;
              border: none !important;
              height: 30px !important;
              width: 0px !important;

              &:hover {
                background-color: transparent !important;
                border: none !important;
              }
              &:focus {
                background: transparent !important;
                box-shadow: none !important;
              }
              &:active {
                background: transparent !important;
                box-shadow: none !important;
              }
            }

            img {
              padding-top: 10px !important;
              max-width: 65%;
            }
          }
        }

        .insigths-tab__header {
          grid-row: header;
        }

        .insights-tab__plot {
          grid-row: body;
        }
      }
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  #no-insights-message {
    margin-top: auto;
    margin-bottom: auto;
    padding-inline: 10%;
    font-weight: bold;
  }

  #insights-banner {
    border-radius: 5px;
    font-size: 14px;
    align-items: center;
    grid-column: descriptions;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    align-self: stretch;
    padding: 20px 10px;
  }
}

.insights-expanded-modal {
  .dialog-header {
    justify-items: end;
  }

  .dialog-content {
    width: 100% !important;
    height: 100% !important;
    overflow: visible;
  }

  .insight-plots,
  .insight-plots .insight-plots__container,
  .insight-plots .insight-plots__container .insight-plot {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
  }
}
