#upload-link {
  display: flex;
  flex-direction: column;
  margin: auto;
  gap: 15px;
  #upload-link-modal-header {
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
}

#upload-link-wrapper .individual-body {
  margin: auto;
  width: 100% !important;
}
