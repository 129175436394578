@import 'src/common/CommonVariables';

.reset-password {
  height: 100%;
  width: 100%;

  &__title {
    justify-content: center;
  }

  &__subtitle {
    justify-content: flex-start;
    font-size: $ft-body__medium !important;
    color: $base-70;
    line-height: 28px !important;
    letter-spacing: -0.02em;
    text-align: left;
  }

  &__title,
  &__subtitle {
    display: flex;
    align-items: center;
    margin-bottom: 20px !important;
  }

  &__body {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    gap: 20px;

    .input-wrapper {
      display: flex;
      flex-direction: column;
      gap: 1px;

      &__title {
        color: $base-80;
      }

      .error-message {
        color: $alert;
        text-align: center;
      }

      &__title,
      .error-message {
        font-size: $ft-body__medium !important;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        line-height: 28px !important;
        letter-spacing: -0.02em;
      }
    }

    .button-wrapper {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
  }
}
