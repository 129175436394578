.summary-layer-body-container {
  grid-row: body;
  display: grid;
  gap: 20px;
  grid-template-columns: [filters] 250px [table] auto;
  overflow: hidden;
  padding: 16px 24px 24px 24px;

  &::-webkit-scrollbar {
    width: 15px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    border: 5px solid white !important;
  }

  &::-webkit-scrollbar-track-piece:start {
    background: transparent;
  }

  .MuiTableContainer-root {
    max-height: none !important;
  }
}

.summary-layer-filtering {
  grid-column: filters;
  overflow-y: scroll;
}

.summary-layer-table {
  grid-column: table;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .summary-layer-switch-head {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    margin-bottom: 30px;
    gap: 15px;
    align-items: center;

    .summary-layer-switch-head-title {
      font-weight: 800;
      font-size: 1rem;
    }
  }

  .summary-layer-block {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .summary-layer-preview-block {
      display: contents;
      justify-content: center;
      align-items: center;

      > div .preview-table,
      .preview-rows-table {
        border-radius: 10px;
      }
    }
  }
}
