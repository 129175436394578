@import 'src/common/CommonVariables';

/* Transformations column */

.transform-column-container {
  max-width: 400px;
  min-width: 300px;
  padding: 0px 14px 0px 14px;
  display: flex;
  flex-direction: column;
  gap: 15px;

  .new-column-input-label {
    white-space: normal;
    font-size: 12px;
    word-break: normal;
  }
}

.navigation-title {
  display: flex;
  justify-content: space-between;

  .title {
    width: 100%;
    display: flex;
  }

  svg {
    align-self: center;
  }
}

.operation-builder-summary {
  margin-bottom: 15px;
  word-break: auto-phrase;

  &:hover {
    cursor: pointer;
  }
}

/* Transformations column & Formulat builder modal*/

.operation-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 1em;
}

.operation-builder-field {
  width: 100%;
  border-radius: 4px;
  border: 1px solid $base-40;
  padding: 12px 16px;
  min-height: 32px;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;

  .operation-item {
    padding: 4px 8px;
    border-radius: 4px;
    align-self: center;
    text-wrap: nowrap;
  }

  .operation-item-column {
    background-color: $base-80;
    color: white;
  }
}

/* Formulat builder modal*/

.operation-builder-modal {
  width: 1000px !important;
  height: 600px !important;
  max-height: 95vh !important;
  max-width: 90vw !important;
}

.operation-builder-header {
  display: flex;
  justify-content: space-between;
}

.operation-builder__content {
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 20px;
  display: flex;
  flex-direction: column;

  .validation-errors {
    display: flex;
    flex-direction: column;
    padding-top: $s;
  }

  .operations-container {
    display: flex;
    gap: 20px;
    flex-direction: row;
    justify-content: space-evenly;
    overflow: hidden;

    .operation-section {
      width: 100%;
      margin-top: $s;
      padding: $m;
      background-color: $base-10;
      border-radius: 15px;

      & > p {
        margin-bottom: 15px;
      }

      & > .MuiFormControl-root,
      .MuiTextField-root {
        width: 100%;
      }
    }

    #search-column {
      overflow-y: scroll;
    }
  }

  .operation-wrapper {
    .operation-builder-field {
      border-right: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    button {
      border-left: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      height: auto !important;
      &:focus {
        box-shadow: unset;
      }
    }
  }
}

.operation-title {
  display: block;
  color: $base-70;
  font-family: $ft-system-bold;
  font-size: $ft-body__medium;
  margin-bottom: $m;
}

.search-column-item {
  cursor: pointer;
  padding: $s;
  border-radius: $radius-xsmall;
  color: $base-80;

  &:hover {
    background-color: $base-40;
  }
}

.operators-container {
  margin-top: $m;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;

  .operators-item {
    text-align: center;
    color: $base-70;
    font-size: xx-large;
    border: 1px solid $base-30;
    width: 50%;
    box-sizing: border-box;
    padding: $s $m;
    font-size: 1.5rem;
    margin-left: -1px; /* avoide borders overlapping*/
    margin-top: -1px; /* avoide borders overlapping*/

    &:hover {
      background-color: $base-40;
      cursor: pointer;
    }
  }
}

#add-number-container {
  display: flex;

  .add-number-button {
    border-radius: $radius-small;
    margin-left: $xs;
  }
}
