@import 'src/common/CommonVariables';

.merge-dataset {
  .merge-pointer {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .merge-body-header {
    display: flex;
    width: 100%;
    gap: 15px;
    align-items: center;
    .column-details-select {
      height: fit-content;
    }
  }
}
