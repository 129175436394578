@import 'src/common/CommonVariables';

@font-face {
  font-family: ft-system-bold;
  src: url(./assets/fonts/ft-system/bold.otf);
}

@font-face {
  font-family: ft-system-regular;
  src: url(./assets/fonts/ft-system/regular.otf);
}

// This style rule removes the check mark
// from the multiselect component globally
.MuiSelect-select svg {
  display: none !important;
}

.App {
  background: $base-10;
}

// Platform scrolls
::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
}

::-webkit-scrollbar {
  width: 0.4em !important;
}

::-webkit-scrollbar-track {
  background-color: $base-30 !important;
  border-radius: 1rem !important;
}

::-webkit-scrollbar-thumb {
  background-color: $base-40 !important;
  border-radius: 1rem !important;
}
