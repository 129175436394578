@import '../CommonVariables.scss';

.error {
  color: $alert;
  background-color: $color-12;
}
.failed {
  color: $alert;
  background-color: $alert-95;
}

.success {
  color: #26c092;
  background-color: #26c0923a;
}

.info {
  color: $color-5;
  background-color: #00758233;
}

.alertBox {
  display: flex;
  padding: 16px 24px 16px 24px;
  gap: 10px;
  align-items: center;
  border-radius: 10px;
}
