@import '../CommonVariables.scss';

.dialog-composed {
  width: 95vw !important;
  height: 90vh !important;
  max-width: 1600px !important;
  max-height: 1200px !important;
}

.dialog-content {
  box-sizing: border-box;
  width: 100% !important;
  height: 100% !important;
}

.center-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 978px;
  max-width: 1024px;
  user-select: none;
  color: $base-90;
}

.individual-body {
  display: flex;
  justify-content: center;
  width: 90% !important;
  background: white;
  padding: 0 !important;
  align-items: center;
}

.modal-wrapper > div {
  background-color: white;
  width: 100%;
}

.Mui-focused .MuiOutlinedInput-notchedOutline {
  outline: none !important;
  border: 1px solid !important;
  border-color: $accent !important;
  box-shadow: $focusBox-shadow-color !important;
}

.smallBtn {
  width: fit-content !important;
}

.errMsg {
  font-size: $ft-body__medium !important;
  color: red !important;
  margin-top: 0.35em !important;
}

.modalWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1024px;
  user-select: none;
  font-family: ft-system-regular;
  color: $base-90;
  font-family: ft-system-regular;
}

.modalWrapper > div {
  background-color: white;
  width: 100%;
  font-family: ft-system-regular;
}

.modalWrapper:focus-visible {
  outline: none !important;
}

.modalHeader {
  border-radius: 1em 1em 0em 0em;
  border-bottom: $base-30 1px solid;
}

.headerContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modalBody {
  font-size: $ft-body__medium;
}

.modalBody label {
  margin-bottom: 5px;
}

.subHeader {
  color: $base-90;
  margin-bottom: 2em;
  margin-top: 2em;
}

.subHeader > * {
  font-family: ft-system-regular !important;
}

.modal-header-close {
  min-width: 24px !important;
}

.modalButton {
  display: flex;
  font-family: ft-system-regular;
  flex-direction: row;
  justify-content: center;
  padding: 10px 24px 14px;
  gap: 10px;
  width: 100%;
  border-radius: 16px !important;
  flex: none;
  order: 4;
  flex-grow: 0;
  color: #fff !important;
  background-color: $base-90 !important;
  border-color: $base-30 !important;
  text-transform: capitalize;
  user-select: none;
  padding: 10px 20px;
  box-shadow: none !important;
}

.color-dark {
  color: $base-90 !important;
}

.modalButton:hover {
  background-color: #ffffff !important;
  color: $base-90 !important;
}

.modalButton:disabled {
  color: $base-30 !important;
  background-color: $base-20 !important;
}

.modalFooter {
  display: flex;
  flex-direction: column;
  border-top: $base-30 1px solid;
  gap: 0.8em;
  border-radius: 0em 0em 1em 1em;
}

.modalFooter .footerContent {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}

.modalHeader .headerContent,
.modalBody .bodyContent,
.modalFooter .footerContent {
  padding: 1em 2em;
}

.modalFooter .halfFtBtn {
  width: 47%;
  font-weight: bold;
  height: auto;
}

.modalFooter .wholeFtBtn {
  width: 100%;
  font-weight: bold;
}

form *::placeholder {
  color: $base-60 !important;
  opacity: 0.5 !important;
}

form .display-column {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5em;
}

.CustomImage {
  margin: auto;
  margin-top: 1rem;
  height: 8rem;
  user-select: none;
}

.CustomDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  user-select: none;
}

.resize-body {
  min-width: 978px;
  max-height: 750px;
}

@media (max-width: 1024px) {
  .modalWrapper {
    max-width: 975px;
  }

  .resize-body {
    min-width: 600px;
  }
}

@media (max-width: 975px) {
  .modalWrapper {
    max-width: 700px;
  }

  .resize-body {
    min-width: 500px;
  }
}

@media (max-height: 1024px) {
  .sub-title-1,
  .sub-title-2 {
    font-size: 0.85rem !important;
  }
}
