@import 'src/common/CommonVariables';

.merge-dataset-body {
  padding-top: 1em !important;
  max-height: 64vh !important;
  min-height: 50vh !important;
  overflow-y: auto;
}

.merge-dataset-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}

.merge-dataset {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  justify-content: center;
  margin: 0;
  margin-top: 15px;
}

.merge-dataset-selected {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  width: calc(50% - 12px);
  min-width: 400px;
  padding: 12px;
  border: solid 1px $base-30 !important;
  border-radius: 12px;

  > * {
    width: 100%;
  }
  &__main {
    margin-left: 2px;
    color: $base-70;
  }
  &__select,
  &__name {
    border-radius: 10px;
  }
  &__name {
    display: flex;
    align-items: center;
    padding: 5px;
    min-height: 50px;
    gap: 12px;
    background-color: $base-10;
  }
}

.merge-main-selected {
  border: solid 1px $accent !important;
}

.alfa-feature {
  color: $accent !important;
  max-width: fit-content !important;
  border: solid 1px $base-30 !important;
  border-radius: 15px !important;
  background-color: $accent-92 !important;
  > * {
    display: flex;
    align-items: center;
    padding: 10px !important;
  }
}

.main-merge-add {
  min-height: 60px;
  display: flex;
  align-items: center;
  gap: 20px;
}
