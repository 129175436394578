.save-form-block {
  gap: 2% !important;
}
.save-form-block .save-section-one {
  width: 60% !important;
  .display-column {
    gap: 5px;
  }
}
.save-form-block .save-section-two {
  width: 28% !important;
  & label {
    margin-bottom: 5px !important;
  }
}
