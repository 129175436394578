@import 'src/common/CommonVariables';
#feature-engieering {
  display: grid;
  grid-template-rows: [header] auto [body] 1fr [footer] 85px !important;

  &__body {
    overflow-y: auto;
    .transformation-head {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      width: 100%;
      margin-bottom: 10px;
      .transformation-head-title {
        width: 70%;
      }
    }

    .transformation-block {
      display: flex;
      width: calc(
        100vw - 190px
      ); //190 is the width of the navbar and the space between the navbar and the content
      height: calc(100% - 90px);
      background-color: $base-10;
      border-radius: 10px;
      &__asset {
        width: 100%;
        display: contents;
        > div .preview-table {
          border-radius: 10px;
        }
        .wrapp-preview-table,
        .wrapp-rows-table {
          max-height: calc(
            100vh - 230px
          ); // 230 is the height of the header and the footer
        }
      }
      &__loading {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: auto;
      }
      &__manager {
        border-left: 2px solid $base-60;
        height: auto;
        .transform-manager-selected-row {
          display: flex;
          flex-direction: row;
          margin-top: -35px;
          padding: 2px 4%;
          width: 92%;
          justify-content: space-between;
          align-items: center;
          p {
            margin-left: 10px;
            margin-top: 12px;
            margin-bottom: 12px;
            max-width: 280px;
            overflow: hidden;
            text-wrap: nowrap;
            text-overflow: ellipsis;
          }
        }
        .transformation-manager-selected-content,
        .transformation-block-manager-content {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 1.3em;
          font-size: $ft-body__medium;
          min-width: 300px;
          min-height: 340px;
          #transformation-block-title {
            font-size: 24px;
            font-family: ft-system-bold !important;
          }
        }

        .transformation-manager-selected-content {
          gap: 0px;
          border-top: 1px solid $base-60;
          .transformation-selected,
          .transformation-menu-disabled,
          .transformation-menu {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            > div {
              margin: 0 1em;
              gap: 10px;
              display: flex;
              align-items: center;
            }
          }
          .transformation-selected {
            cursor: default;
            svg {
              margin-right: 10px;
            }
          }
          .transformation-sub-menu-disabled,
          .transformation-sub-menu {
            cursor: pointer;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            > p {
              margin-left: 20px;
            }
            > div {
              margin-right: 15px;
            }
          }
          .transformation-sub-menu:hover,
          .transformation-menu:hover {
            background-color: $base-60;
          }
          .transformation-menu-disabled,
          .transformation-sub-menu-disabled {
            opacity: 0.5;
            cursor: default;
          }
        }
        .transformation-message {
          display: flex;
          flex-direction: column;
          align-items: center;
          white-space: pre-line;
          text-align: center;
        }
        .transformation-buttons {
          display: flex;
          padding-bottom: 20px;
          flex-direction: column;
          gap: 12px;
          .transformation-button {
            font-size: $ft-body__medium;
            padding: 4px 12px;
            border-radius: 8px;
          }
        }
      }
    }
  }

  &__footer {
    justify-content: space-between;
    display: flex;
  }

  .transformation-filtering {
    padding-top: 10px !important;
    gap: 8px !important;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    overflow: hidden;

    .transformation-filtering-numerical {
      display: flex;
      flex-direction: column;
      width: 90%;
      align-items: center;

      .display-row {
        width: 100%;
        justify-content: space-evenly;

        .display-column {
          width: 100%;
        }
      }
    }

    .transformation-filtering-categorical {
      width: 95%;
      padding-left: 5%;
      background: $base-10;
      overflow-y: scroll;

      .filtering-categorical-button-selection {
        display: flex;
        gap: 8px;
        justify-content: space-evenly;

        button {
          font-size: 12px;
          padding: 6px, 12px, 6px, 12px;
          border-radius: 8px;
        }
      }
      .filter-checkbox {
        color: $base-80;
        border-radius: 8px;
      }
    }
  }

  .transformation-filtering-save-button {
    margin-top: 15px !important;
    background: none !important;
    color: black !important;
    width: 90% !important;
  }

  .input-bottom-label {
    color: $base-70;
  }
}

@media (max-width: 1200px) {
  #feature-engieering {
    &__body {
      .transformation-block {
        &__asset {
          .wrapp-preview-table,
          .wrapp-rows-table {
            max-height: calc(
              100vh - 251px
            ); // 251 is the height of the header and the footer in a column like display
          }
        }
      }
    }
  }
}

@media (max-width: 780px) and (max-height: 750px) {
  #feature-engieering {
    &__body {
      .transformation-block {
        height: calc(100% - 110px);
      }
    }
  }
}
