@import 'src/common/CommonVariables';

.radioWrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  margin: 0.5em 0em;
}

.responseWrapper {
  display: flex;
  justify-content: center;
  gap: 5%;
  flex-direction: row;
  margin: 1em 0em;
}

.responseButton,
.radioButtons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: auto;
  min-width: 67px;
  max-width: 100%;
  padding: 6px 24px;
  line-height: 20px;
  color: $base-90 !important;
  border: 2px solid $base-30 !important;
  border-radius: 8px !important;
  order: 1;
  flex: none;
  flex-grow: 0;
  background: white !important;
  text-transform: none !important;
}

.responseWrapper > .responseButton {
  font-family: ft-system-bold !important;
}

.responseWrapper > .radioButtons {
  font-family: ft-system-regular !important;
}

.radioMargin {
  display: flex;
  flex-direction: column;
  padding: 1em;
  align-items: center;
}

.selected {
  border-color: $base-90 !important;
  background: $base-90 !important;
  color: white !important;
}
