.submenu-regular-wrapp {
  width: 90%;
  height: 100%;
  padding: 6px 15px;
  max-height: 40vh;
  overflow-y: overlay;
  overflow-x: hidden;
  .MuiOutlinedInput-root {
    border-radius: 13px !important;
  }
  button:focus {
    box-shadow: none !important;
  }
}

.submenu-regular-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.submenu-regular-name {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;
}

.submenu-regular-footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  margin: 0px 15px;
  padding-top: 3px;
  border-top: solid 1px #80808026;
}
