@keyframes move-up {
  0% {
    transform: translateY(80px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes move-down {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(80px);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
