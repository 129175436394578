@import 'src/common/CommonVariables';

.chart-correlations-container {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: $m;
  display: flex;
  flex-direction: column;
  justify-content: stretch;

  .chart-scroll-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .chart-correlations-canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding-bottom: 32px;
  }

  &.is-chat {
    // Displaying the chart in the chat message
    padding-top: 0;

    .chart-scroll-container {
      max-height: 400px;
    }

    .chart-correlations-canvas {
      position: relative;
      padding-bottom: 0;
    }
  }
}

/*
* TITLE & MESSAGE
*/

.chart-correlations-container {
  .chart-title {
    position: relative;
    max-width: calc(100% - #{$l} * 2);
    margin-left: $l;
    padding-bottom: $s;
    margin-bottom: $s;
    border-bottom: 1px solid $base-30;

    p {
      font-size: $ft-body__medium;
      font-weight: bold;
      color: $base-80;
    }
  }

  .chart-no-data {
    fill: $base-70;
    text-anchor: middle;
  }
}

/*
* LEGEND 
*/

.chart-correlations-container .chart-legend {
  display: flex;
  gap: $l;
  padding: $m $l;

  .chart-legend {
    &__title {
      font-size: $ft-body__small;
      font-weight: bold;
    }

    &__items {
      display: flex;
      gap: $l;
    }

    &__item {
      display: flex;
      gap: $s;
      align-items: center;
      font-size: $ft-body__small;
    }

    &__color {
      width: $m;
      height: $m;
      border-radius: 50%;
    }
  }
}

/*
* BARS 
*/

.chart-correlations-container rect {
  fill: $base-40;
  transition: all 0.2s ease;

  &.importance--strong {
    fill: $color-1;

    &.hovered {
      stroke: $color-1;
    }
  }

  &.importance--moderate {
    fill: $color-2;

    &.hovered {
      stroke: $color-2;
    }
  }

  &.importance--weak {
    fill: $color-5;

    &.hovered {
      stroke: $color-5;
    }
  }

  &.hovered {
    stroke-width: 2px;
  }
}

/*
* AXIS 
*/

.chart-correlations-container .axis {
  .domain,
  &--left .tick line {
    stroke: transparent;
  }

  &--left .tick.hovered text {
    fill: $accent;
    font-weight: bold;
  }

  &--top .tick line {
    stroke: $base-40;
  }

  .tick text {
    fill: $base-80;
    font-size: $ft-body__small;
  }

  &__label {
    fill: $base-80;
    font-size: $ft-body__medium;
    font-weight: bold;
  }
}

/*
* BARS LABELS
*/

.chart-correlations-container .bar-label {
  font-size: $ft-body__xsmall;

  &[data-strength='strong'] {
    fill: $color-1;
  }
  &[data-strength='moderate'] {
    fill: $color-2;
  }
  &[data-strength='weak'] {
    fill: $color-5;
  }
}
