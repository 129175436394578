@import 'src/common/CommonVariables';

#magic-island {
  width: 100%;
  background-color: $base-0;
  border-radius: 24px;
  padding: 12px 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  //box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
    0px 8px 8px -4px rgba(16, 24, 40, 0.03);
}

.title-container {
  display: flex;
  align-items: center;

  #magic-island-title-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}

.assistant-logo {
  margin-left: -8px;
}

.loading-dots {
  margin-left: 10px;
}

.expandArrow {
  float: right;
  cursor: pointer;
}

.component-container {
  width: 100%;
  transition: opacity 0.45s ease-in-out;
  margin-top: 12px;
}

.pop-in-anim {
  animation-name: pop-in;
  animation-duration: 0.45s;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}

@keyframes pop-in {
  0% {
    opacity: 0;
    transform: scale(0.5, 0.5);
  }

  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}

.slide-in-anim {
  animation-name: slide-in;
  animation-duration: 0.5s;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}

@keyframes slide-in {
  0% {
    opacity: 0;
    transform: scaleX(0.5);
  }

  100% {
    opacity: 1;
    transform: scaleX(1);
  }
}
