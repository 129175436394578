@import 'src/common/CommonVariables';

.filtering-main {
  border-right: 1px solid $base-30;
}

.filtering-header {
  display: flex;
  gap: $m;
  padding-right: 40px;
  padding-bottom: 15px;

  .filtering-header-title-1 {
    font-weight: 600 !important;
  }

  .filtering-icon {
    width: 34px;
    height: 34px;
    border-radius: $radius-xsmall;
    padding: 8px;
    background: $accent;
  }
}

.filtering-divider {
  border-color: $base-30 !important;
}

.filtering-block-1 {
  padding-top: 15px;
  display: flex;
  gap: 10px;
  padding: 20px 10px 0px 5px;
}

.filtering-block-title-1 {
  color: $base-80;
  font-size: $ft-body__medium;
  font-weight: 400;
  margin-bottom: 10px !important;
}

#filtering-input[type='number']::-webkit-inner-spin-button,
#filtering-input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.filtering-select {
  margin: 0px 0px 10px 0px !important;
}
