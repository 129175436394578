@import 'src/common/CommonVariables';

.upload {
  color: $base-80;
  width: 100%;
  &__content {
    padding: 0px 12px;
    &__title {
      font-weight: bold;
      size: 18px;
      line-height: 23.4px;
      display: flex;
      flex-direction: column;
      gap: 4px;
      padding-bottom: 14px;
    }
    &__description {
      font-size: 14px !important;
      line-height: 18.2px !important;
      color: $base-70;
    }
  }
  &__loading {
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-weight: bold;
    &__progress {
      display: flex;
      gap: 10px;
      font-size: bold;
      svg > * {
        fill: $accent;
      }
      &__details {
        display: flex;
        font-size: medium;
        align-items: baseline;
        color: $base-70;
        gap: 10px;
        margin-top: 4px;
      }
    }
  }
}
#upload-footer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 20px;
}
