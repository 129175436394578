@import 'src/common/CommonVariables';

#chat-column {
  box-sizing: border-box;
  width: 90%;
  max-width: 700px;
  height: 100vh;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: hidden;
  z-index: 2;
  padding: 0 24px 56px 24px;
}

#message-box {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column-reverse;
  background-color: $base-10;
}

#ComponentBotBox {
  width: 100%;
  height: 30%;
}

.bot-message {
  font-family: ft-system-regular;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: $base-90;
  max-width: 80%;
  min-width: 50px;
  order: 2;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  background: $base-30;
  border-radius: 4px 12px 12px 12px;
  margin-top: 24px;
  margin-bottom: 24px;
  margin-right: auto;
  margin-left: 5px;
  flex-shrink: 0;
  white-space: pre-line;
  width: fit-content;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
}

.bot-message2,
.bot-message-white,
.bot-message-open-modal,
.bot-message-chart {
  display: flex;
  max-width: 403px;
  min-width: 50px;
  background: $base-0;
  color: $base-90;
  order: 2;
  align-items: center;
  padding: 8px 16px 16px;
  gap: 8px;
  border-radius: 4px 12px 12px 12px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-right: auto;
  margin-left: 5px;
  flex-shrink: 0;
  width: fit-content;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
  user-select: none;
}

.bot-message-white,
.bot-message-open-modal {
  background: $base-0 !important;
}

.bot-message-white > div,
.bot-message-open-modal > div {
  display: flex;
  align-items: center;
  gap: 10px;
}

.bot-message-white > div > img {
  width: 30px;
}

.bot-message-white > div > span {
  font-weight: bold;
  color: $base-90;
}

.bot-message-chart {
  max-width: 100%;
  width: 90%;
  zoom: 1;
  padding: 10px;
}

.bot-message-chart > div {
  margin-top: 10px;
}

.bot-message-component {
  order: 2;
  display: flex;
  max-width: 90%;
  min-width: 50px;
  min-height: 44px;
  align-items: center;
  padding: 8px 16px 12px;
  gap: 8px;
  background: $base-80;
  border-radius: 4px 16px 16px 16px;
  margin-right: auto;
  margin-left: 5px;
  flex-shrink: 0;
  width: fit-content;
}

.bot-message-progress {
  order: 2;
  display: flex;
  max-width: 90%;
  min-width: 50px;
  min-height: 44px;
  align-items: center;
  padding: 8px 16px 12px;
  gap: 8px;
  color: $base-70;
  background: $base-0;
  border-radius: 4px 16px 16px 16px;
  margin-right: auto;
  margin-left: 5px;
  flex-shrink: 0;
  width: fit-content;
}

.bot-message-image-component {
  order: 2;
  display: flex;
  width: 436px;
  max-width: 90%;
  min-width: 50px;
  min-height: 44px;
  align-items: center;
  margin-right: auto;
  margin-left: 5px;
  flex-shrink: 0;
}

.bot-message-2body {
  font-family: ft-system-regular;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: $base-0;
  order: 2;

  width: fit-content;
}

.user-message {
  font-family: ft-system-regular;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: $base-0;
  max-width: 80%;
  min-width: 50px;
  width: fit-content;
  order: 2;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px 12px;
  gap: 8px;
  background: linear-gradient(
    180deg,
    #fd471d -827.27%,
    #d3117c -265.01%,
    #a018c9 309.09%
  );
  border-radius: 12px 12px 4px 12px;
  margin-bottom: 24px;
  margin-right: 5px;
  margin-left: auto;
  flex-shrink: 0;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
}

.user-message2 {
  display: flex;
  max-width: 403px;
  min-width: 50px;
  min-height: 44px;
  color: white;
  order: 2;
  align-items: center;

  padding: 8px 16px 12px;
  gap: 8px;
  background: linear-gradient(
    180deg,
    #fd471d -827.27%,
    #d3117c -265.01%,
    #a018c9 309.09%
  );
  border-radius: 16px 16px 4px 16px;
  margin-right: 5px;
  margin-left: auto;
  margin-bottom: 24px;
  flex-shrink: 0;
  width: fit-content;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
}

.bot-message-file {
  display: flex;
  max-width: 403px;
  min-width: 50px;
  min-height: 44px;
  background: $base-0;
  color: $base-90;
  order: 2;
  align-items: center;
  padding: 8px 16px 12px;
  gap: 8px;
  border-radius: 4px 16px 16px 16px;
  margin-left: 5px;
  margin-right: auto;
  flex-shrink: 0;
  width: fit-content;
}

.bot-message-link-preview {
  display: flex;
  max-width: 403px;
  min-width: 50px;
  min-height: 44px;
  color: white;
  order: 2;
  align-items: center;

  padding: 8px 16px 12px;
  gap: 8px;
  background: $base-80;
  border-radius: 16px 16px 4px 16px;
  margin-left: 5px;
  margin-right: auto;
  flex-shrink: 0;
  width: fit-content;
  margin-bottom: 24px;
}

.user-message-2body {
  font-family: 'ft-system-regular';
  font-style: 'normal';
  font-weight: '400';
  font-size: '16px';
  line-height: '24px';
  letter-spacing: '-0.01em';
  color: '$base-0';
  order: 2;
  width: 'fit-content';
}

.bot-message-2link {
  text-decoration-color: inherit !important;
  font-family: ft-system-regular;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: inherit !important;
  order: 2;

  width: fit-content;

  &:hover {
    color: $accent !important;
  }
}

.message-cicrled-iconComponent {
  width: 35px;
  height: 35px;
  left: 0px;
  top: 8.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $accent;
  box-shadow: 0 0 0 4px rgba(201, 19, 141, 0.2);
  border-radius: 28px;
  flex-direction: row;

  svg {
    fill: $base-0;
  }
}
