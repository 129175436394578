@import 'src/common/CommonVariables';

#playground-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90vh;
  max-width: 95vw;
  width: 100%;
  background-color: white;
  margin: auto;
  border-radius: $radius-medium;
}

#playground-body {
  flex: auto;
  display: grid;
  gap: 24px;
  grid-template-columns: [features] 3.33fr [results] 5fr;
  grid-template-rows: [prediction] 1fr [plot] 3fr;
  max-height: 80vh;
  overflow: hidden;

  &__features {
    grid-column: results;
    grid-row: plot;

    display: flex;
    flex-wrap: wrap;
    gap: $m;
    justify-content: flex-start;
    align-content: baseline;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  &__prediction-scenario {
    grid-column: results;
    grid-row: prediction;
    background: $base-10;
    border-radius: 8px 8px 0 0;
  }

  &__plot {
    grid-column: features;
    grid-row: span 2;
    background: $base-10;
    display: flex;
    align-items: center;
    height: 100%;

    .custom-chart {
      height: inherit;
    }

    #chart {
      height: 100% !important;
    }
  }
}

#scenario-legend-container {
  display: flex;
  gap: 10px;
  align-items: center;
  flex: 100%;
}

.scenario-legend-text {
  font-size: 11px;
  color: $base-70;
}

.scenario-legend {
  width: 24px;
  border-radius: 2px;
  height: 5px;
}

.success-legend {
  background-color: $success;
}

.warning-legend {
  background-color: $warning;
}

.feature-best {
  box-shadow: 0 -2px 0 $success inset;
}

.feature-worst {
  box-shadow: 0 -2px 0 $warning inset;
}

.predictions-from-summary {
  display: flex;
  gap: 8px;
  color: $base-70;
  > button {
    gap: 5px;
  }
  > p {
    align-self: center;
  }
}
