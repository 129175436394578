#predict-scenario-container {
  width: inherit;

  #predict-scenario__header {
    width: inherit;
    height: 80px;
    padding: 0px 24px;
    border-radius: 8px 8px 0 0;
    background-image: url('/assets/imgs/modalHeaderBackground.png');
    display: flex;
    justify-content: space-between;
    align-items: center;

    &--title {
      display: flex;
      gap: 8px;
      padding: 8px 16px;
      border-radius: 12px;
      background: rgba(255, 255, 255, 0.25);
      backdrop-filter: blur(16.5px);
      color: white;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    padding: 16px 24px;
    gap: 24px;
    font-size: 14px;
  }
}
