@import 'src/common/CommonVariables';

#crosstabs-body {
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: $base-80;

  .MuiInputBase-root {
    border-radius: 10px;
  }

  &__selectors,
  &__name {
    margin-top: 10px;
    display: flex;
    width: 100%;
    span {
      color: $accent;
    }
  }

  &__name {
    flex-direction: column;
  }

  &__selectors {
    gap: 20px;
    > div {
      display: flex;
      flex-direction: column;
      width: 50%;
    }
    p {
      color: $base-70;
      margin-top: 10px;
      margin-right: 10px;
    }
  }
}

.crosstabs-footer {
  display: flex;
  gap: 20px;
  justify-content: flex-end !important;
}
