#start-chat {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 498px;
  user-select: none;
  background: #ffffff;
  border-radius: 40px;
  user-select: none;
  .hand-image {
    margin: auto;
    margin-top: 1rem;
    height: 8rem;
  }
  .start-chat-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem !important;
  }
  .start-title {
    font-family: ft-system-bold !important;
    font-style: normal;
    font-weight: 800;
    font-size: 42px;
    line-height: 130%;
    text-align: center;
    letter-spacing: -0.03em;
    color: #000000;
    width: 350px;
    order: 2;
  }
  .start-content {
    margin-top: 24px;
    text-align: center;
    width: 430px;
    font-size: 20px;
    line-height: 160%;
    letter-spacing: -0.02em;
    color: #667085;
    flex: none;
    order: 3;
    flex-grow: 0;
  }
  .signup-button {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: -0.02em;
    cursor: pointer;
    text-align: center;
  }
}
