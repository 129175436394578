@import 'src/common/CommonVariables';

.select-row {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  margin-bottom: 12px;

  label {
    flex-shrink: 0;
    font-family: 'ft-system-regular';
    color: $base-80;
  }

  > *:not(label) {
    width: 100%;
    flex-shrink: 1;
    overflow: hidden;
  }
}

.menu-item--checkbox {
  font-family: 'ft-system-regular';
  border-radius: 8px;
  padding: 8px 14px;

  .MuiCheckbox-root {
    &:hover {
      background-color: transparent;
    }

    svg {
      width: 1.25rem;
      height: 1.25rem;

      &[data-testid='CheckBoxOutlineBlankIcon'] {
        fill: $base-40;
      }

      &[data-testid='CheckBoxIcon'] {
        fill: $base-80;
      }
    }
  }
}
