@import 'src/common/CommonVariables';

#home-container {
  display: grid;
  grid-template-columns: 150px calc(100% - 150px);
  height: 100vh;
  &__content {
    width: 100%;
    position: relative;
    &::before,
    &::after {
      content: '';
      width: 300px;
      height: 100vh;
      z-index: -1;
      position: absolute;
      bottom: 0;
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: bottom;
    }

    &::before {
      left: 0;
      background-image: url('../../src/assets/imgs/background-sticks-left.svg');
    }

    &::after {
      right: 0;
      background-image: url('../../src/assets/imgs/background-sticks-right.svg');
    }
    &__myspace {
      position: fixed;
      width: calc(100% - 150px); // 150px is the width of the sidebar
      height: 100%;
      z-index: 1;
      background-color: white;
    }
  }
}
