.CustomCheckbox {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  > input {
    margin-right: 0.5rem;
    width: 1rem;
    height: 1rem;
  }
}
