.save-header {
  display: flex;
}
.save-form-block {
  gap: 2% !important;
}
.save-form-block .save-section-one {
  width: 60% !important;
  display: flex;
  flex-direction: column;
  word-break: break-all;
}
.save-form-block .save-section-two {
  width: 28% !important;
  & label {
    margin-bottom: 5px !important;
  }
}
.subHeader {
  .dark-fill-icon-no-hover {
    margin-left: -10px;
  }
}
