@import 'src/common/CommonVariables';

#nav-bar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  &__menu {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    .nav-bar__menu-item {
      display: flex;
      padding: 8px;
      gap: 5px;
      align-items: center;
      cursor: pointer;
      > div {
        height: 20px;
        width: 20px;
      }
    }
    .nav-bar__menu-item:hover {
      background-color: $base-30;
    }
    .navbar-selected-tab {
      color: $accent;
      & svg > * {
        fill: $accent !important;
      }
    }
  }
  &__privacy-link {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
    a {
      font-size: 14px;
      color: #344054;
      text-decoration: none;
      letter-spacing: -0.02em;
      user-select: none;
    }
    .session-button {
      justify-content: flex-start;
      border: none;
      text-transform: none;
      max-width: none;
    }
    .navbar-account-select {
      border-radius: 8px;
      .navbar-render-account-select {
        display: flex;
        align-items: center;
        margin-right: 10px;
      }
      .MuiSelect-select {
        padding: 11.5px 14px;
        padding-right: 40px !important;
      }
    }
  }
  img {
    width: 80%;
  }
  .MuiSelect-select svg {
    display: inherit !important;
  }
}

// This stiles are for elements that appear outisede the current html tree that is why they are outisde of the scope of the nav-bar id
li.nav-bar-account-menu-item.Mui-focusVisible {
  background-color: inherit !important;
}

li.nav-bar-account-menu-item:hover {
  background-color: $base-30 !important;
}
