@import 'src/common/CommonVariables';

.insight-plots {
  width: 100%;

  &__title {
    font-size: $ft-body__xsmall;
    font-weight: bold;
    color: $base-80;
    text-align: center;
    margin-bottom: $m;
  }

  &__container {
    display: flex;
    gap: $m;
  }
}

.insight-plot {
  width: 100%;
  min-height: 300px;

  rect.bar {
    fill: $info;
    transition: all 0.2s ease;

    &:hover {
      stroke: $info;
      stroke-width: 2px;
    }
  }

  .domain,
  .tick line {
    stroke: transparent;
  }

  .tick text {
    fill: $base-80;
    font-size: 8px;
  }

  .chart-tooltip {
    text-align: center;

    span {
      display: inline-block;
      padding: $xs $s;
      background-color: $info;
      border-radius: $xs;
      font-size: $ft-body__xsmall;
      font-weight: bold;
      color: $base-10;
      max-width: 100%;
    }
  }

  &--vars {
    rect.bar {
      fill: $color-11;

      &:hover {
        stroke: $color-11;
      }
    }

    .path-line {
      stroke: $color-11;
    }

    #gradient_area stop:first-child {
      stop-color: rgba(190, 14, 120, 0.05);
    }

    .chart-tooltip span {
      background-color: $color-11;
    }
  }

  &--freq {
    rect.bar {
      fill: $color-5;

      &:hover {
        stroke: $color-5;
      }
    }

    .chart-tooltip span {
      background-color: $color-5;
    }
  }
}

.insight-plots.large {
  .insight-plots__title {
    font-size: $ft-body__large;
  }

  .tick text {
    font-size: 12px;
  }

  .insight-plots__container .insight-plot {
    padding: $xl;
  }
}

.chart-line-canvas {
  .focus-dot {
    fill: $color-11;
  }
}
