@import 'src/common/CommonVariables';

#load-assets-component {
  .radio-table {
    tr button {
      margin: auto;
      position: unset;
    }
  }

  .load-action-footer {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    gap: 20px;
  }
  .load-dataset-management-select {
    font-size: 12px;
    font-weight: 700;
    border-radius: 8px;
    .MuiSelect-select {
      padding: 11.5px 14px;
      padding-right: 40px !important;
    }
  }

  .subheader-load {
    display: flex;
    justify-content: space-between;
    color: $base-70;
    padding: 1em 0;
    align-items: center;
  }

  .subheader-load > * {
    font-family: ft-system-regular !important;
  }

  .table-container {
    display: flex;
    justify-content: center;
    width: 100%;
    max-height: calc(
      100vh - 275px
    ); // 275px is the height of the space the header and the footer take
    overflow-y: scroll;
    margin-right: 20px;
    border-radius: 8px;
    border: 1px solid #ddd;
    position: relative;
    .uploaded {
      border-left: 3px solid $success;
    }
  }

  .radio-table {
    border-collapse: collapse;
    border-spacing: 0px;
    width: 100%;
    height: auto;
    max-height: 400px;
    overflow-y: auto;
  }

  .radio-table tr {
    height: 50px !important;
  }

  .radio-table .select {
    border-color: $base-90 !important;
    background: $base-90 !important;
    color: white !important;
  }

  .radio-table th,
  .radio-table td {
    padding: 8px;
    text-align: left;
  }

  .radio-table th,
  .radio-table tr {
    border-bottom: 1px solid #ddd;
  }

  .radio-table th {
    background-color: #f2f2f2;
  }

  .radio-table input[type='radio'] {
    margin-right: 6px;
  }

  .radio-table tbody tr {
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .radio-table tbody tr:hover {
    background-color: #f5f5f5;
  }

  .radio-table input[type='radio']:checked ~ label {
    font-weight: bold;
  }

  .radio-table label {
    display: inline-block;
    margin-right: 15px;
  }

  .show-pattern-image {
    font-size: $ft-body__small;
    text-decoration: underline !important;
    margin: 0 !important;
    cursor: pointer;
  }

  .model-card-view,
  .model-card {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

    label {
      word-break: break-all;
    }
  }

  .model-card-view {
    color: $base-70;
  }

  .model-metadata {
    display: flex;
    gap: 5px;
    color: $base-60;
    align-items: center;
    .chip {
      border-radius: 8px !important;
    }
  }
}
