// Base colors
$base-90: #101828;
$base-80: #344054;
$base-70: #667085;
$base-60: #98a2b3;
$base-50: #b4bcc8;
$base-40: #d0d5dd;
$base-30: #eaecf0;
$base-20: #f2f4f7;
$base-10: #f9fafb;
$base-0: #ffffff;

// Accent colors
$accent-32: #812083;
$accent: #a82aab;
$accent-52: #cb3acf;
$accent-62: #d663d9;
$accent-72: #e18ce3;
$accent-92: #f6def7;

// Semantic colors
$info-95: #e7f8fe;
$info: #0dbcf2;
$info-30: #087191;

$success-95: #e7fef5;
$success: #0df29f;
$success-30: #089160;

$warning-95: #fef3e7;
$warning: #f2890d;
$warning-30: #915208;

$alert-95: #fee7e9;
$alert: #f20d28;
$alert-30: #910818;

// Categories
$color-1: #02044c;
$color-2: #0f46b1;
$color-3: #b1ebfd;
$color-4: #003e3a;
$color-5: #007682;
$color-6: #d8fa77;
$color-7: #7c0016;
$color-8: #db4d26;
$color-9: #ffd597;
$color-10: #36025f;
$color-11: #be0e78;
$color-12: #ffafd6;
