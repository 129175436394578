@import 'src/common/CommonVariables';

.stepper-process,
.stepper-process-2 {
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 12px 0;
  font-size: 12px;
  .stepper-inner {
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $base-40;
    color: $base-70;
    border-radius: 50%;
    font-size: 11px;
    padding-right: 1px;
    padding-bottom: 1px;
  }
  span {
    width: 80px;
    background: $base-40;
    height: 1px;
  }
  .active {
    background: $accent;
    color: $base-0;
  }
}
.stepper-process-2 {
  gap: 5px;
}
