@import './Colors.scss';

$ft-system-bold: 'ft-system-bold';
$ft-system-regular: 'ft-system-regular';

$ft-header__title: 28px;
$ft-header__headline: 20px;
$ft-header__subheading: 18px;

$ft-body__large: 16px;
$ft-body__medium: 14px;
$ft-body__small: 12px;
$ft-body__xsmall: 11px;

$ft-label: 12px;
