.column-detail-wrapp {
  display: flex;
  flex-direction: column;
  gap: 1px;
  padding: 0px 10px 5px 10px;
  min-width: 100px;
  border: 1px solid;
  border-radius: 5px;

  .column-detail-progress,
  div {
    display: flex;
    gap: 10px;
    width: auto;
  }

  .column-detail-progress {
    align-items: baseline;
    div {
      width: 100%;
      display: block;
      .MuiLinearProgress-root {
        height: 8px;
        border-radius: 4px;
      }
      .MuiLinearProgress-root::before {
        background-color: gray;
        height: 8px;
        border-radius: 4px;
      }
    }
  }
}
