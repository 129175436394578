// Inner spacing/inter text spacing
$xs: 4px;
$s: 8px;
$m: 12px;
$l: 24px;
$xl: 32px;

// Website section spacing
$w-xs: 48px;
$w-s: 64px;
$w-m: 80px;
$w-l: 96px;
$w-xl: 128px;
